
import { defineComponent } from 'vue'
import { resetStore } from '@/store'
import Icon from '@/components/Icon/index.vue'
import palette from '../../../palette'

interface ISetupReturn {
  palette: Record<string, string>
  goBack ():void
}

export default defineComponent({
  components: { Icon },
  setup ():ISetupReturn {
    function goBack ():void {
      resetStore()
    }

    return {
      palette: palette.brand,
      goBack
    }
  }
})
