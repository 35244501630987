
import { defineComponent, computed, ComputedRef, SetupContext } from 'vue'
import colors from 'tailwindcss/colors'

import { useStore } from '@/hooks/store'
import { INavigation, useNavigation } from '@/hooks/navigation'
import Icon from '@/components/Icon/index.vue'
import Wizard from '@/components/Wizard/index.vue'
import { brand } from '../../../palette'

interface ISetupReturn {
  emit: SetupContext['emit']
  back: INavigation['back']
  brandColors: Record<string, string>
  colors: Record<string, string>
  label: ComputedRef<string>
  canGoBack: ComputedRef<boolean>
  canShowAdditionalControlAndInfo: ComputedRef<boolean>
}

export default defineComponent({
  components: { Icon, Wizard },
  emits: ['close-box'],
  setup (_, { emit }:SetupContext):ISetupReturn {
    const store = useStore()
    const { back } = useNavigation()

    const label = computed<string>(() => {
      if (store.feedbackType === 'ISSUE') {
        return 'Reporte um problema'
      }
      if (store.feedbackType === 'IDEA') {
        return 'Nos fale a sua ideia'
      }
      if (store.feedbackType === 'OTHER') {
        return 'Abra sua mente'
      }
      return 'O que você tem em mente?'
    })

    const canGoBack = computed<boolean>(() => {
      return store.currentComponent === 'SelectFeedbackType'
    })

    const canShowAdditionalControlAndInfo = computed<boolean>(() => {
      return store.currentComponent !== 'Success' && store.currentComponent !== 'Error'
    })

    return {
      emit,
      back,
      colors,
      brandColors: brand,
      label,
      canGoBack,
      canShowAdditionalControlAndInfo
    }
  }
})
