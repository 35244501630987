
import { ComputedRef, computed, defineComponent, reactive } from 'vue'

import { setMessage } from '@/store'
import { useNavigation } from '@/hooks/navigation'
import { useStore } from '@/hooks/store'
import services from '@/services'

import Icon from '../Icon/index.vue'

type State = {
  feedback: string;
  isLoading: boolean;
  hasError: Error | null;
}

interface SetupReturn {
  state: State;
  submitAFeedback(): Promise<void>;
  submitButtonIsDisabled: ComputedRef<boolean>;
}

export default defineComponent({
  components: { Icon },
  setup (): SetupReturn {
    const store = useStore()
    const { setSuccessState, setErrorState } = useNavigation()
    const state = reactive<State>({
      feedback: '',
      isLoading: false,
      hasError: null
    })

    const submitButtonIsDisabled = computed<boolean>(() => {
      return !state.feedback.length
    })

    function handleError (error: Error) {
      state.hasError = error
      setErrorState()
    }

    async function submitAFeedback (): Promise<void> {
      setMessage(state.feedback)
      state.isLoading = true

      try {
        const response = await services.feedbacks.create({
          type: store.feedbackType,
          text: store.message,
          page: store.currentPage,
          apiKey: store.apiKey,
          device: window.navigator.userAgent,
          fingerprint: store.fingerprint
        })

        if (!response.errors) {
          setSuccessState()
        } else {
          setErrorState()
        }
      } catch (error) {
        handleError(error)
      } finally {
        state.isLoading = false
      }
    }

    return {
      state,
      submitAFeedback,
      submitButtonIsDisabled
    }
  }
})
