
import { defineComponent, SetupContext } from 'vue'
import Icon from '../../components/Icon/index.vue'

interface ISetupReturn {
  emit: SetupContext['emit'];
}

export default defineComponent({
  components: { Icon },
  emits: ['open-box'],
  setup (_, { emit }: SetupContext): ISetupReturn {
    return { emit }
  }
})
