
import { defineComponent, SetupContext } from 'vue'
import { TStoreState } from '@/store'

interface ISetupReturn {
  handleSelect(type:TStoreState['feedbackType']): void
}

export default defineComponent({
  setup (_, { emit }:SetupContext):ISetupReturn {
    function handleSelect (type:TStoreState['feedbackType']): void {
      emit('select-feedback-type', type)
      emit('next')
    }

    return { handleSelect }
  }
})
